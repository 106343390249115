import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout';
import TextBanner from '../components/text-banner/text-banner';
import OverlayBlockTeam from '../components/overlay-block-team/overlay-block-team';
import StaffMembersBlock from '../components/staff-block/staff-block';

class DigitalMarketing extends React.Component {
    render() {
        const digitalTextBanner = get(this, 'props.data.digitalTextBanner');
        const digitalOverlayBlockTeam = get(this, 'props.data.digitalOverlayBlockTeam');
        const digitalStaffMembersBlock = get(this, 'props.data.digitalStaffMembersBlock');
        return (
            <Layout location={this.props.location}>
                <Seo title="Meet The Team"/>
                    <div className="container-fluid container-fluid--page">
                        <div className="meet-team">
                            <TextBanner textOne={digitalTextBanner.textOne} textTwo={digitalTextBanner.textTwo} />
                            <OverlayBlockTeam image={digitalOverlayBlockTeam.backgroundImage.url} headingText={digitalOverlayBlockTeam.headingText}
                                descriptionText={digitalOverlayBlockTeam.descriptionText} boxOneNumber={digitalOverlayBlockTeam.boxOneNumber}
                                boxOneText={digitalOverlayBlockTeam.boxOneText} boxTwoNumber={digitalOverlayBlockTeam.boxTwoNumber}
                                boxTwoText={digitalOverlayBlockTeam.boxTwoText} />
                            <StaffMembersBlock members={digitalStaffMembersBlock.nodes} />
                        </div>
                    </div>
            </Layout>
        )
    }
}

export default DigitalMarketing;

export const query = graphql`
query DigitalMarketing {
    digitalTextBanner: contentfulTextBanner(textBannerName: {eq: "digitalTextBanner"}) {
        textBannerName
        textOne
        textTwo
    }
    digitalOverlayBlockTeam: contentfulOverlayBlockTeam(overlayBlockName: {eq: "digitalOverlayBlockTeam"}) {
        overlayBlockName
        headingText
        descriptionText
        boxOneNumber
        boxOneText
        boxTwoNumber
        boxTwoText
        backgroundImage {
            url
        }
    }
    digitalStaffMembersBlock: allContentfulStaffMembersBlock(
        filter: {staffMembersBlockName: {glob: "*digitalStaffMembersBlock*"}}
      ) {
        nodes {
          name
          description
          image {
            url
          }
        }
    }
}
`
